footer {
    background: #004A74;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px 79px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.footer-left {
    width: 100%;
}

.footer-left img {
    width: 257.84px;
    height: 183.1px;
    filter: brightness(0) invert(1);
}

.footer-right {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.footer-right-box h4 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
}

.footer-right-box ul {
    margin-top: 10px;
}

.footer-right-box li {
    padding: 2px 0px;
    list-style: none;
}

.footer-right-box li a {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
    text-decoration: none;
}

.logo4 img {
    filter: brightness(0) invert(1);
}

.logo4::after, .logo4::before {
    display: flex;
    content: "";
    width: 500px;
    height: 5px;
    margin: 0px 50px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(83, 202, 245, 0.8) 0%, rgba(217, 217, 217, 0) 100%);
}

footer p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #FAFAFA;
    margin-top: 20px;
}