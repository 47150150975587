* {
    margin: 0;
    padding: 0;

}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    height: 96px;
    background-color: #FAFAFA;
}

.nav-left {
    padding: 13px 50px
}

.nav-left img {
    width: 110px;
    cursor: pointer;
}

.nav-right {
    padding: 0 30px;
}

.nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
}

.nav-link {
    margin: 0 20px;
    padding-top: 25px;
    list-style: none;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.03em;
    display: flex;
    flex-direction: column;
}

.nav-link::after {
    content: "";
    background: linear-gradient(90deg, #0F497E 0%, #53CAF5 109.3%);
    width: 25%;
    height: 3px;
    margin-top: 5px;
}

.nav-link a {
    text-decoration: none;
    color: #4F4F4F;
}