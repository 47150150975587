.heading {
    font-family: 'Cinzel';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747;
    padding-top: 40px;
}

.home-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FAFAFA;
}

.logo {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-top .logo img {
    width: 50px;
    height: 50px;
}

.logo::after, .logo::before {
    display: flex;
    content: "";
    width: 100px;
    height: 5px;
    margin: 0px 50px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(83, 202, 245, 0.8) 0%, rgba(217, 217, 217, 0) 100%);
}

.bg {
    margin-top: 20px;
    height: 214px;
    background-image: url(https://s3-alpha-sig.figma.com/img/f885/964d/0b1c84156a895a05b4c189baa4dc8f03?Expires=1655078400&Signature=dB08TzI9y2d~EWZSz-gWcOhNX909RKUBebi00T9kfN02qQDyhSSHs6WPTNBgLljuF7kYHy12UQ1RCJoheBh6U8OrXsa4FJ9Q-TlNtmkMxHSYF1pmOJCmFMiUvddT-3BZWgt8gZvdF7Kp2u3ORor66N3aM4t7gV~yuoCA3mXhT0~UbHoS0yvcfESuJaUQldZ472Tos451~m8RtxR1QMN55Enk-kkBRypJNYEjSZoXcXokCEj1thMISCsKlCfzLL-wGUU5mua4CrtW8nxBLpHmH5fi4pANOg2uo7pFtX4TzuUGBvrfjTuv2~XJk~lGa3j-ijVdOsKDyX8CQLy9mba0Lg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
    background-size: cover;
    background-position: center;

}

.home-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 295px;
    gap: 40px;
    background-image: linear-gradient(#fbfbfbad, #d7d1d1a6), url(images/bg.jpg);
    background-size: cover;
}

.home-cards p {
    font-family: 'Roboto Flex';
    font-style: italic;
    font-weight: 1000;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #474747;

}

.logo2::after, .logo2::before {
    display: flex;
    content: "";
    width: 500px;
    height: 5px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(83, 202, 245, 0.8) 0%, rgba(217, 217, 217, 0) 100%);
}

.card-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    gap: 50px;
    width: 400px;
    height: 304px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.card img {
    position: relative;
    Width: 80px;
}

.card p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #474747;
    text-align: justify;
    padding: 0 15px;
}

.paragraph p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: justify;
    letter-spacing: 0.03em;
    color: #474747;
    padding: 20px;

}


.vision {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    gap: 20px;
    background: url(images/bg2.jpg);
    background-size: cover;
    background-position: center;
}

.vision-box {
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
}

.vision h2 {
    color: #FAFAFA;
    font-weight: 400;
}

.vision p {
    font-family: 'Roboto Flex';
    font-style: italic;
    font-weight: 1000;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FAFAFA;
    text-align: center;
    padding: 50px 280px;
}

.mission {
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    gap: 50px;
}

.mission h2 {
    font-family: 'Cinzel';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747;
}

.mission-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}


.mission-box-left {
    Width: 550px;
    Height: 405px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.mission-box-left img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.mission-box-right {
    Width: 550px;
    Height: 405px;
}


summary {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none;
}

summary {
    padding: .80em 1em;
    cursor: pointer;
}

summary p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 1000;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.1em;
    color: #909090;

}

details {
    margin-bottom: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

details div {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #474747;
    width: 520px;
    padding: 0 15px 5px 15px;
    background-color: #F5F5F5;
}


details[open] summary {
    background-color: #F5F5F5;
    border-radius: 10px;
}

details[open] summary p {
    color: #474747;
}

.social-res {
    background: #F5F7F8
}

.social-res-box {
    width: 550px;
    height: 580px;
}

.social-res-box details[open] summary {
    background-color: #fff;
}

.social-res-box details[open] div {
    background-color: #fff;
}


.founder-message {
    padding: 60px 295px;
    background-image: linear-gradient(#fbfbfbad, #d7d1d1a6), url(images/bg.jpg);
    background-size: cover;
}

.founder {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.img {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    border: 1px solid #000;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 3px;
}

.img img {
    width: 95%;
    border-radius: 50%;
    border: 2px solid #000;
    padding: 5px;
}

.founder h3 {
    color: #000;
    font-weight: bolder;
    padding: 0;
}

.logo3::after, .logo3::before {
    display: flex;
    content: "";
    width: 240px;
    height: 5px;
    margin: 0px 50px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(83, 202, 245, 0.8) 0%, rgba(217, 217, 217, 0) 100%);
}

.founder h4 {
    margin-top: -20px;
    font-family: 'Roboto Flex';
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    padding: 0px 200px;

}

.founder p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    letter-spacing: 0.03em;
    color: #474747;
    padding: 20px 80px;
    margin-bottom: 30px;
}

.group-companies {
    background-color: #FAFAFA;
}

.group-companies-box {
    margin: 50px 0;
    display: flex;
    justify-content: center;
}

.img-box {
    border-radius: 10px;
}

.img-box-1 {
    width: 300px;
}

.img-box-2 {
    width: 493px;
}

.img-box-3 {
    width: 274px;
}

.img-box img {
    height: 95%;
    width: 95%;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.img-box p {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 1000;
    font-size: 24px;
    line-height: 150%;
    text-align: justify;
    letter-spacing: 0.1em;
    color: #474747;
    text-align: center;
}